
.data-center {
    /*position: fixed;*/
    top: -40px;
    bottom: 0;
    width: 100%;
    height: calc(100% + 40px);
    background-color: #F7F8FC;

    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

.data-content {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 0;
    height: 100%;
    background-color: #F7F8FC;
    margin: 0 auto;

    .filter-box {
        background: #ffffff;
        height: 80px;
        display: flex;
        align-items: center;

        .filter-item {
            margin-left: 32px;
            display: flex;
            align-items: center;

            .icon-box {
                width: 40px;
                height: 39px;
                background: #E2F9FB;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 22px;
                }
            }

            .item-name {
                margin-left: 12px;
                font-size: 16px;
            }

            &:nth-of-type(2) {
                margin-left: 54px;
            }
        }
    }

    .data-select {
        width: 200px;
        margin-left: 15px;
    }

    .data-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &:nth-of-type(2) {
            margin-top: 22px;
        }

        .title {
            position: relative;
            height: 50px;

            img {
                width: 218px;
                height: 50px;
            }

            .text {
                position: absolute;
                left: 0;
                display: inline-block;
                width: 218px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 20px;
                color: #fff;
            }
        }

        .charts-top {
            display: flex;
            justify-content: space-between;
            margin: 20px 20px 0;
            position: relative;

            .left-title {
                font-size: 16px;
            }

            .right-title {
                font-size: 16px;
                border-left: 1px solid #333333;
                margin-left: 10px;
                padding-left: 10px;
                color: #2DC079;
            }

            &:after {
                position: absolute;
                content: '';
                bottom: -18px;
                width: 100%;
                height: 1px;
                background: #EEEEEE;
            }

            .right-box {
                display: flex;

                .right-item {
                    display: flex;
                    align-items: center;

                    &:last-child {
                        margin-left: 20px;
                    }

                    .item-icon {
                        width: 14px;
                        height: 14px;
                        border-radius: 2px;
                        margin-right: 9px;

                        &.green {
                            background: #2DC079;
                        }

                        &.yellow {
                            background: #F5AF23;
                        }

                        &.cyan {
                            background: #BAD80B;
                        }
                    }

                    .item-name {
                        color: #666666;
                    }
                }

                .data-select {
                    height: 30px;

                    ::v-deep .el-input--suffix {
                        height: 30px;

                        .el-input__inner {
                            height: 30px;
                        }

                        .el-input__suffix {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .data-body {
            margin-top: 22px;

            .box {
                display: flex;

                .box-item {
                    background: #ffffff;
                    /*width: 33%;*/
                    margin-left: 25px;

                    &:first-child {
                        margin-left: 0;
                        min-width: calc((100% - 520px) /2 - 20px);
                    }
                    &:nth-child(2) {
                        min-width: 400px;
                    }
                    &:nth-child(3) {
                        min-width: calc((100% - 370px) /2 + 20px);
                    }
                }

                .left {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    width: 1%;
                    margin-right: 20px;

                    .exam-num {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: 100%;
                        background: #ffffff;
                        box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                        border-radius: 6px;
                        margin-right: 20px;
                        position: relative;

                        &:after {
                            position: absolute;
                            content: '';
                            height: 5px;
                            width: 100%;
                            background: #2DC079;
                            bottom: 0;
                        }

                        .icon {
                            width: 116px;
                            height: 116px;
                            line-height: 116px;
                            background: #2DC079;
                            border-radius: 50%;
                            text-align: center;
                            margin-right: 40px;

                            i {
                                font-size: 60px;
                                color: #fff;
                            }
                        }

                        .num-show {
                            display: flex;
                            flex-direction: column;

                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                color: #333333;
                            }

                            .num {
                                font-size: 48px;
                                font-weight: bold;
                                color: #2DC079;
                                font-family: Source Han Sans CN;
                            }
                        }
                    }

                    .certified-num {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: 100%;
                        background: #ffffff;
                        box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                        border-radius: 6px;
                        margin-right: 20px;
                        position: relative;

                        &:after {
                            position: absolute;
                            content: '';
                            height: 5px;
                            width: 100%;
                            background: #18C1FF;
                            bottom: 0;
                        }

                        .icon {
                            width: 116px;
                            height: 116px;
                            line-height: 116px;
                            background: #18C1FF;
                            border-radius: 50%;
                            text-align: center;
                            margin-right: 40px;

                            i {
                                font-size: 60px;
                                color: #fff;
                            }
                        }

                        .num-show {
                            display: flex;
                            flex-direction: column;

                            .text {
                                font-size: 18px;
                                font-weight: 400;
                                color: #333333;
                            }

                            .num {
                                font-size: 48px;
                                font-weight: bold;
                                color: #18C1FF;
                                font-family: Source Han Sans CN;
                            }
                        }
                    }
                }

                .right {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 452px;
                    height: 281px;
                    background-color: #fff;
                    box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                    border-radius: 6px;

                    .pass-rate {
                        display: inline-block;
                        position: absolute;
                        left: 198px;
                        top: 157px;
                        font-size: 18px;
                        color: #333;
                    }
                }
            }

            .bottom-box {
                display: flex;
                margin-top: 23px;

                .box-item {
                    background: #ffffff;
                    width: 25%;
                    margin-left: 19px;
                    border-radius: 6px;
                    box-shadow: 0px 0px 10px 0px rgba(14, 168, 94, 0.1);

                    &:first-child {
                        margin-left: 0;
                    }

                    .item-content {
                        padding: 20px;

                        .top-content {
                            .top-title {
                                color: #666666;
                            }

                            .top-num {
                                margin-top: 20px;
                                font-size: 36px;
                                color: #000000;
                            }
                        }

                        .middle-content {
                            position: relative;
                            margin-top: 20px;
                            height: 40px;
                            display: flex;
                            align-items: center;

                            &:after {
                                content: '';
                                position: absolute;
                                height: 1px;
                                width: 100%;
                                bottom: -24px;
                                background: #EEEEEE;
                            }
                        }

                        .first-title {
                            margin-right: 9px;
                        }

                        i {
                            margin-right: 9px;
                            font-size: 18px;
                        }

                        .middle-left {
                            i {
                                color: #2DC079;
                            }
                        }

                        .middle-right {
                            margin-left: 70px;

                            i {
                                color: #FF5500;
                            }
                        }

                        .bottom-content {
                            margin-top: 43px;
                            display: flex;
                            align-items: center;

                            .bottom-right {
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .class-item {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
        border-radius: 6px;
        margin-top: 20px;
        padding: 40px 30px 20px 20px;
        box-sizing: border-box;

        .class-name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
        }

        .class-top {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2DC079;
            margin-top: 28px;
        }

        .class-table {
            margin-top: 10px;

            .table-title {
                height: 51px;
                line-height: 51px;
                background: #E7F6EF;
                padding: 0 70px;
            }

            .title-item {
                display: inline-block;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
                text-align: center;

                &:nth-child(1) {
                    width: 40%;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 10%;
                }

                &:nth-child(3) {
                    width: 10%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 15%;
                }

                &:nth-child(6) {
                    width: 15%;
                }
            }

            .table-body {
                display: flex;
                flex-direction: column;

                .body-item {
                    display: flex;
                    align-items: center;
                    height: 90px;
                    padding: 0 70px;
                    border-bottom: 1px solid #EEEEEE;

                    .title-item {
                        color: #333;
                        font-weight: 400;
                    }
                    .no-pass {
                        color: #FF0000;
                        font-size: 30px;
                    }
                    .pass {
                        color: #2DC079;
                        font-size: 30px;
                    }
                    .student-name {
                        display: flex;
                        align-items: center;

                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .more {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2DC079;
            margin-top: 27px;
            cursor: pointer;

            i {
                font-size: 20px;
            }
        }
    }
}
.small-rate {
    ::v-deep .el-progress-bar__outer {
        border-radius: 0;
        .el-progress-bar__inner {
            border-radius: 0;
            .el-progress-bar__innerText {
                display: none;
            }
        }
    }
}
.lin-icon {
    width: 100%;
    height: 10px;
    margin-left: 79%;
    .top-icon {
        display: inline-block;
        height: 10px;
        width: 3px;
        background-color: #18C1FF;
    }
}
.filter-boxs {
    margin-top: 20px;
}
.is-exam_rate {
    width: 400px !important;
}
.has-echart {
    display: flex;
    flex-direction: column;
}
.no-data-echart {
    flex: 1;
    height: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-no-data {
    display: none;
}
